import { useContext } from "react";
import { toast } from "react-toastify";
import { useUpdateUser, UserContext } from "features/user";
import { Button } from "ui";
import { ErrorToast } from "features/report";
import { useForm, UsernameInput, Form } from "forms";
import PropTypes from "prop-types";

export default function OnboardingUsername({ onComplete }) {
  const { user } = useContext(UserContext);
  const { mutate, isPending } = useUpdateUser();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const onError = (error, data) => {
    const apiErrors = Object.entries(error.parseError(data));
    if (apiErrors.length) {
      apiErrors.forEach(([name, message]) => {
        setError(name, { message, type: "custom" });
      });
    } else {
      toast(
        <ErrorToast
          errorMessage={error.details.message}
          errorProps={{
            defaultReason: "issue",
            defaultPage: "onboarding",
            apiError: error.details,
          }}
        />,
        { limit: 1 },
      );
    }
  };

  return (
    <div data-testid="user-onboarding-username">
      <h2 className="capitalize old-mb-5 old-fs-1">Claim Your Username</h2>

      <Form onSubmit={handleSubmit((data) => mutate(data, { onSuccess: onComplete, onError }))}>
        <div>
          <small className="text-primary-400 font-roman">Email</small>
          <p>{user.email}</p>
        </div>

        <UsernameInput
          name="username"
          control={control}
          defaultValue={user.username || ""}
          label="Username"
        />

        <Button
          type="submit"
          color="primary"
          trackingName="continue onboarding"
          isDisabled={!!errors.username || isPending}
          isLoading={isPending}
          fullWidth
        >
          Continue
        </Button>
      </Form>
    </div>
  );
}

OnboardingUsername.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
