import { useMemo, useState } from "react";
import { useParams, Link, generatePath, useSearchParams } from "react-router-dom";
import {
  BriefStatusModal,
  BriefStatus,
  briefStatusContent,
  useBrief,
  briefPropTypes,
} from "features/brief";
import { NotFound } from "features/report";
import { PageLayout, NavbarSpacer } from "features/navigation";
import {
  ScrollSections,
  Row,
  Col,
  Container,
  ScrollToTopButton,
  ModalClickContainer,
  Tabs,
  Tab,
  Chip,
  Button,
  User,
} from "ui";
import routes from "default/routes";
import BriefDetails from "default/pages/Brief/BriefDetails";
import BriefWorkspace from "default/pages/Brief/BriefWorkspace";
import BriefSubmissions from "default/pages/Brief/BriefSubmissions";
import prospectIcon from "images/icons/prospect100icon-inverted.svg";

function CallToActionButton({ brief }) {
  const { callToAction } = briefStatusContent(brief)[brief.status];

  if (!brief.entered || !callToAction) return null;

  return (
    <div className="old-d-none old-d-lg-block old-align-items-end old-w-100">
      <Button
        color="primary"
        size="lg"
        fullWidth
        as={Link}
        to={callToAction.path}
        trackingName={callToAction.trackingName}
        data-testid="brief-cta"
      >
        {callToAction.text}
      </Button>
    </div>
  );
}
CallToActionButton.propTypes = {
  brief: briefPropTypes.isRequired,
};

export default function Brief() {
  const { id } = useParams();
  const [finalPage, setFinalPage] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useBrief(id);
  const brief = data ?? {};
  const setTab = (newTab) =>
    setSearchParams((prev) => {
      prev.set("tab", newTab);
      return prev;
    });

  const tab = useMemo(() => {
    if (searchParams.get("tab")) return searchParams.get("tab");

    if (brief.id === null) return "details";

    if (
      ["submitting", "proposing", "selecting"].includes(brief.status) &&
      brief.user_submission_count > 0
    )
      return "workspace";

    if (["voting", "judging"].includes(brief.status)) return "submissions";
    return "details";
  }, [brief, searchParams]);

  const showTabs =
    brief.user_submission_count > 0 || ["voting", "judging", "completed"].includes(brief.status);

  if (!isLoading && Object.keys(brief).length <= 0) {
    return (
      <NotFound
        text={"Bummer. \n The requested brief could not be found or does not exist."}
        issuePage="enter_brief"
      />
    );
  }

  return (
    <PageLayout
      isLoading={isLoading}
      pageName="Brief"
      meta={{
        image: brief.banner?.url || brief.thumbnail,
        description: `Oditi Brief: ${brief.title}`,
      }}
      className="text-white"
      showFooter={finalPage}
      navbarProps={{
        size: "none",
        icon: prospectIcon,
      }}
    >
      {Object.keys(brief).length > 0 && (
        <ScrollSections data-testid="brief">
          <ScrollSections.ScrollSectionFluid className="old-contain-paint old-text-white">
            <img
              className="old-position-absolute old-w-100 old-h-100 old-object-fit-cover"
              src={brief.banner?.url || brief.thumbnail}
              alt="brief banner"
            />

            <div className="old-position-absolute old-top-0 old-start-0 old-bg-black old-w-100 old-h-100 old-bg-opacity-50" />

            <Container className={!showTabs && "old-pb-6 old-mb-6"}>
              <NavbarSpacer />

              <Row className="old-py-lg-6 old-gx-6">
                <Col
                  xs={12}
                  lg={4}
                  className="old-d-flex old-flex-column old-all-mb-3 old-all-mb-lg-4"
                >
                  <hr className="old-d-lg-none old-d-block old-flex-grow-1 old-border-4 old-border-offwhite old-opacity-100 old-rounded-pill" />
                  <User
                    name={brief.organisation.name}
                    avatarProps={{
                      src: brief.organisation.icon,
                    }}
                    as={Link}
                    to={generatePath(routes.organisation, {
                      id: brief.organisation.id,
                    })}
                    classNames={{
                      base: "bg-transparent gap-3",
                      name: "normal-case text-xl hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-1 before:origin-left before:transition-transform before:duration-500 before:scale-x-0 before:bg-current before:absolute before:left-0 before:bottom-0",
                    }}
                  />

                  <ModalClickContainer
                    modal={BriefStatusModal}
                    modalProps={{
                      brief,
                    }}
                  >
                    <BriefStatus brief={brief} />
                  </ModalClickContainer>

                  <CallToActionButton brief={brief} />
                </Col>

                <Col xs={12} lg={8} className="old-order-first">
                  <div className="old-mw-brief-text">
                    <h2 className="old-mb-3 old-home-header-font old-mt-lg-n3 old-mt-4">
                      {brief.title}
                    </h2>
                  </div>
                  <div className="old-d-flex old-gap-2 old-gap-md-3 old-mb-2 old-mb-lg-0">
                    {brief.design_interests.map((category) => (
                      <Link
                        key={category}
                        className="bg-primary-500 bg-opacity-50 hover:bg-opacity-100 hover:bg-primary-100 rounded-lg px-3 py-2 uppercase old-fs-12 old-fs-md-14"
                        to={`${routes.briefs}?category=${category}`}
                      >
                        {category.replace("_", " ")}
                      </Link>
                    ))}
                  </div>
                </Col>
              </Row>
            </Container>

            {showTabs && (
              <Container className="old-w-100 flex  old-align-items-center old-justify-content-center old-mb-6 old-pb-6 pt-4">
                <hr className="old-d-none old-d-sm-block old-flex-grow-1 old-border-4 old-border-offwhite old-opacity-100 old-rounded-pill" />

                <Tabs
                  size="lg"
                  radius="full"
                  color="primary"
                  onSelectionChange={setTab}
                  selectedKey={tab}
                  fullWidth
                  classNames={{
                    base: "max-w-full sm:max-w-[75%] lg:max-w-[50%] 2xl:max-w-[33%] sm:mx-4",
                    tabList: "sm:p-2 gap-0 sm:gap-2",
                    tab: "uppercase text-xs sm:text-base h-9 sm:h-auto sm:py-2",
                  }}
                >
                  <Tab key="details" title="Overview" />

                  {brief.user_submission_count > 0 && (
                    <Tab
                      key="workspace"
                      title={
                        <div className="flex items-center space-x-1 sm:space-x-2">
                          <span>Workspace</span>
                          <Chip size="sm" variant="faded" className="px-0.5 sm:px-1">
                            {brief.user_submission_count}
                          </Chip>
                        </div>
                      }
                    />
                  )}

                  {["voting", "judging", "completed"].includes(brief.status) && (
                    <Tab key="submissions" title="Submissions" />
                  )}
                </Tabs>

                <hr className="old-d-none old-d-sm-block old-flex-grow-1 old-border-4 old-border-offwhite old-opacity-100 old-rounded-pill" />
              </Container>
            )}
          </ScrollSections.ScrollSectionFluid>

          {(tab === "details" || !tab) && <BriefDetails brief={brief} />}

          {tab === "workspace" && <BriefWorkspace brief={brief} />}

          {tab === "submissions" && <BriefSubmissions brief={brief} setFinalPage={setFinalPage} />}
        </ScrollSections>
      )}

      <ScrollToTopButton />
    </PageLayout>
  );
}
