import { useContext } from "react";
import { useUpdateUser, UserContext } from "features/user";
import { Row, Col, Button, Avatar } from "ui";
import {
  Select,
  Autocomplete,
  DatePicker,
  Input,
  useForm,
  Form,
  yupResolver,
  Controller,
} from "forms";
import { object, string, date } from "yup";
import { ErrorToast } from "features/report";
import { parseDate } from "@internationalized/date";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import iso3311a2 from "iso-3166-1-alpha-2";
import dayjs from "dayjs";

const schema = object().shape({
  given_name: string().strict().required("First Name is a required field"),
  family_name: string().strict().required("Last Name is a required field"),
  dob: date().required().max(new Date(), "Date of birth cannot be in the future"),
  gender: string().required("Gender is a required field"),
  country_code: string()
    .required("Country is required")
    .oneOf(iso3311a2.getCountries(), "Not a valid country"),
});

export default function OnboardingUser({ onComplete }) {
  const { user } = useContext(UserContext);
  const { mutate, isPending } = useUpdateUser();
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onError = (error, data) => {
    const apiErrors = Object.entries(error.parseError(data));
    if (apiErrors.length) {
      apiErrors.forEach(([name, message]) => {
        setError(name, { message, type: "custom" });
      });
    } else {
      toast(
        <ErrorToast
          errorMessage={error.details.message}
          errorProps={{
            defaultReason: "issue",
            defaultPage: "onboarding",
            apiError: error.details,
          }}
        />,
        { limit: 1 },
      );
    }
  };

  return (
    <div data-testid="user-onboarding-user">
      <h2 className="capitalize old-mb-5 old-fs-1">Tell us about yourself</h2>

      <Form
        onSubmit={handleSubmit((data) =>
          mutate(
            {
              ...data,
              dob: dayjs(data.dob).format("YYYY-MM-DD"),
              country_code: iso3311a2.getCode(data.country_code),
            },
            { onSuccess: onComplete, onError },
          ),
        )}
      >
        <Row className="gap-5">
          <Col xs={12} md={6}>
            <Input
              {...register("given_name")}
              label="First Name"
              defaultValue={user.given_name}
              errorMessage={errors.given_name?.message}
              isInvalid={!!errors.given_name}
            />
          </Col>

          <Col>
            <Input
              {...register("family_name")}
              label="Last Name"
              defaultValue={user.family_name}
              errorMessage={errors.family_name?.message}
              isInvalid={!!errors.family_name}
            />
          </Col>
        </Row>

        <Row className="gap-5">
          <Col xs={12} md={6}>
            <Controller
              control={control}
              name="dob"
              defaultValue={user.dob ? parseDate(user.dob) : null}
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Date of Birth"
                  showMonthAndYearPickers
                  errorMessage={errors.dob?.message}
                  isInvalid={!!errors.dob}
                />
              )}
            />
          </Col>

          <Col>
            <Select
              {...register("gender")}
              label="Gender"
              defaultSelectedKeys={[user.gender || ""]}
              errorMessage={errors.gender?.message}
              isInvalid={!!errors.gender}
            >
              <Select.SelectItem key="male">Male</Select.SelectItem>
              <Select.SelectItem key="female">Female</Select.SelectItem>
              <Select.SelectItem key="non_binary">Non Binary</Select.SelectItem>
              <Select.SelectItem key="not_to_say">Prefer not to say</Select.SelectItem>
            </Select>
          </Col>
        </Row>

        <Autocomplete
          {...register("country_code")}
          label="Country"
          defaultSelectedKey={user.country_code}
          errorMessage={errors.country_code?.message}
          isInvalid={!!errors.country_code}
        >
          {iso3311a2.getCodes().map((code) => (
            <Autocomplete.AutocompleteItem
              key={code}
              value={code}
              startContent={
                <Avatar
                  alt={iso3311a2.getCountry(code)}
                  className="w-6 h-6"
                  imgProps={{ loading: "lazy" }}
                  src={`https://flagcdn.com/${code.toLowerCase()}.svg`}
                />
              }
            >
              {iso3311a2.getCountry(code)}
            </Autocomplete.AutocompleteItem>
          ))}
        </Autocomplete>

        <Button
          type="submit"
          color="primary"
          isLoading={isPending}
          trackingName="continue onboarding"
          fullWidth
        >
          Continue
        </Button>
      </Form>
    </div>
  );
}

OnboardingUser.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
