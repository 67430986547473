import { generatePath, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { tv } from "tailwind-variants";
import routes from "default/routes";
import { Avatar, Listbox, ListboxItem, User } from "ui";
import { UserBadgeList } from "features/user";
import { formatScore, formatPosition } from "../utils";
import { leaderboardEntryPropTypes } from "../schemas";

const positionColor = (pos) => {
  switch (pos) {
    case 1:
      return "text-yellow-500";
    case 2:
      return "text-gray-400";
    case 3:
      return "text-orange-500";
    default:
      return "text-default-500";
  }
};

function MedalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-medal"
    >
      <path d="M7.21 15 2.66 7.14a2 2 0 0 1 .13-2.2L4.4 2.8A2 2 0 0 1 6 2h12a2 2 0 0 1 1.6.8l1.6 2.14a2 2 0 0 1 .14 2.2L16.79 15" />
      <path d="M11 12 5.12 2.2" />
      <path d="m13 12 5.88-9.8" />
      <path d="M8 7h8" />
      <circle cx="12" cy="17" r="5" />
      <path d="M12 18v-2h-.5" />
    </svg>
  );
}

const LeaderboardEntry = motion.create(Link);

const leaderboardList = tv({
  slots: {
    base: "w-full items-center content-between overflow-hidden",
    list: "gap-3 mt-3",
    item: "bg-default-100",
    itemContent: "flex items-center w-full gap-3 p-1 overflow-hidden",
    content: "flex flex-col justify-between",
    position: "flex items-center gap-1 ordinal font-light",
    nameWrapper: "flex items-center flex-wrap overflow-hidden",
    name: "uppercase",
    badge: "ml-1",
    score: "font-roman",
  },
  variants: {
    size: {
      lg: {
        position: "text-md",
        list: "gap-2",
        name: "text-lg",
        score: "text-xs",
        badge: "!size-4",
      },
      xl: {
        position: "text-md",
        name: "text-xl",
        badge: "!size-5",
      },
    },
  },
  defaultVariants: {
    size: "xl",
  },
});

export default function LeaderboardList({ entries, scoreName, isLoading = false, size = "xl" }) {
  const className = leaderboardList({ size });

  if (isLoading) {
    return (
      <div className={className.list()}>
        <User.Loading />
        <User.Loading />
      </div>
    );
  }

  return (
    <Listbox
      aria-label="Leaderboard List"
      items={entries}
      classNames={{
        base: className.base(),
        list: className.list(),
      }}
    >
      {(entry) => (
        <ListboxItem
          key={entry.user.id}
          as={LeaderboardEntry}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          to={generatePath(routes.userProfile, {
            username: entry.user.username,
          })}
          color="primary"
          className={className.item()}
        >
          <div className={className.itemContent()}>
            <Avatar name={entry.user.username} src={entry.user.icon} size={size} />
            <div className={className.content()}>
              <small
                className={`${className.position()} ${positionColor(entry.position)} ${
                  entry.position === 1 ? "data-[hover=true]:animate-bounce" : ""
                }`}
              >
                {entry.position <= 3 && <MedalIcon />}
                {formatPosition(entry.position)}
              </small>

              <div className={className.nameWrapper()}>
                <span className={className.name()} data-slot="name">
                  {entry.user.username}
                </span>
                <UserBadgeList className={className.badge()} badges={entry.user.badges} />
              </div>

              <small className={className.score()}>
                {`${formatScore(entry.score)} ${scoreName === "briefs" ? "briefs completed" : scoreName}`}
              </small>
            </div>
          </div>
        </ListboxItem>
      )}
    </Listbox>
  );
}

LeaderboardList.propTypes = {
  entries: leaderboardEntryPropTypes.isRequired,
  scoreName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  size: PropTypes.oneOf(["lg", "xl"]),
};
