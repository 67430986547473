import PropTypes from "prop-types";

function Form({ children, onSubmit }) {
  return (
    <form onSubmit={onSubmit} className="flex flex-col gap-5">
      {children}
    </form>
  );
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
