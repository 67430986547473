import { DatePicker } from "@nextui-org/react";
import { twMerge } from "tailwind-merge";
import styled from "styled-components";

const StyledDatePicker = styled(DatePicker).attrs((props) => ({
  radius: props.radius ?? "full",
  dateInputClassNames: {
    inputWrapper: twMerge("px-4", props.dateInputClassNames?.inputWrapper || ""),
  },
}))``;

export default StyledDatePicker;
