import { useContext } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import iso3311a2 from "iso-3166-1-alpha-2";
import { parseDate } from "@internationalized/date";
import { object, string, date } from "yup";
import { UserContext, useUpdateUser } from "features/user";
import { ErrorToast } from "features/report";
import { Avatar, Modal } from "ui";
import { useForm, Form, Controller, DatePicker, Select, yupResolver, Autocomplete } from "forms";

const schema = object().shape({
  dob: date().required().max(new Date(), "You must be at least 13 years old"),
  gender: string().required("Gender is required"),
  country_code: string()
    .required("Country is required")
    .oneOf(iso3311a2.getCountries(), "Not a valid country"),
});

export default function VerifyVoteModal({
  onHide,
  onSuccess,
  title,
  show = false,
  footerProps = {},
}) {
  const { user } = useContext(UserContext);
  const { mutate, isPending } = useUpdateUser();
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onError = (error, data) => {
    const apiErrors = Object.entries(error.parseError(data));
    if (apiErrors.length) {
      apiErrors.forEach(([name, message]) => {
        setError(name, { message, type: "custom" });
      });
    } else {
      toast(
        <ErrorToast
          errorMessage={error.details.message}
          errorProps={{
            defaultReason: "issue",
            defaultPage: "profile",
            apiError: error.details,
          }}
        />,
        { limit: 1 },
      );
    }
  };

  return (
    <Modal isOpen={show} onClose={onHide} data-testid="add-user-info-modal">
      <Modal.ModalBody className="p-6">
        <section className="flex flex-col my-4 text-center justify-center">
          <span className="text-3xl">{title}</span>
          <span className="uppercase text-primary-600">Help us keep competitions fair</span>
        </section>

        <Form
          onSubmit={handleSubmit((data) =>
            mutate(
              {
                ...data,
                dob: dayjs(data.dob).format("YYYY-MM-DD"),
                country_code: iso3311a2.getCode(data.country_code),
              },
              { onSuccess, onError },
            ),
          )}
        >
          <Controller
            control={control}
            name="dob"
            defaultValue={user.dob ? parseDate(user.dob) : null}
            render={({ field }) => (
              <DatePicker
                {...field}
                label="Date of Birth"
                showMonthAndYearPickers
                errorMessage={errors.dob?.message}
                isInvalid={!!errors.dob}
              />
            )}
          />

          <Select
            label="Gender"
            {...register("gender")}
            defaultSelectedKeys={[user.gender || ""]}
            errorMessage={errors.gender?.message}
            isInvalid={!!errors.gender}
          >
            <Select.SelectItem key="male">Male</Select.SelectItem>
            <Select.SelectItem key="female">Female</Select.SelectItem>
            <Select.SelectItem key="non_binary">Non Binary</Select.SelectItem>
            <Select.SelectItem key="not_to_say">Prefer not to say</Select.SelectItem>
          </Select>

          <Autocomplete
            {...register("country_code")}
            label="Country"
            defaultSelectedKey={user.country_code}
            errorMessage={errors.country_code?.message}
            isInvalid={!!errors.country_code}
          >
            {iso3311a2.getCodes().map((code) => (
              <Autocomplete.AutocompleteItem
                key={code}
                startContent={
                  <Avatar
                    alt={iso3311a2.getCountry(code)}
                    className="w-6 h-6"
                    imgProps={{ loading: "lazy" }}
                    src={`https://flagcdn.com/${code.toLowerCase()}.svg`}
                  />
                }
              >
                {iso3311a2.getCountry(code)}
              </Autocomplete.AutocompleteItem>
            ))}
          </Autocomplete>

          <Modal.ModalActionsFooter
            onCancel={onHide}
            cancelProps={{
              variant: "light",
            }}
            confirmProps={{
              type: "submit",
              isLoading: isPending,
            }}
            {...footerProps}
          />
        </Form>
      </Modal.ModalBody>
    </Modal>
  );
}

VerifyVoteModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  show: PropTypes.bool,
  footerProps: PropTypes.shape({}),
};
